// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-account-js": () => import("./../../../src/pages/app/account.js" /* webpackChunkName: "component---src-pages-app-account-js" */),
  "component---src-pages-app-cpaform-js": () => import("./../../../src/pages/app/cpaform.js" /* webpackChunkName: "component---src-pages-app-cpaform-js" */),
  "component---src-pages-app-instrument-condition-report-[id]-js": () => import("./../../../src/pages/app/instrument/conditionReport/[id].js" /* webpackChunkName: "component---src-pages-app-instrument-condition-report-[id]-js" */),
  "component---src-pages-app-instrument-edit-[id]-js": () => import("./../../../src/pages/app/instrument/edit/[id].js" /* webpackChunkName: "component---src-pages-app-instrument-edit-[id]-js" */),
  "component---src-pages-app-instrument-index-js": () => import("./../../../src/pages/app/instrument/index.js" /* webpackChunkName: "component---src-pages-app-instrument-index-js" */),
  "component---src-pages-app-instrument-media-[id]-js": () => import("./../../../src/pages/app/instrument/media/[id].js" /* webpackChunkName: "component---src-pages-app-instrument-media-[id]-js" */),
  "component---src-pages-app-instrument-new-js": () => import("./../../../src/pages/app/instrument/new.js" /* webpackChunkName: "component---src-pages-app-instrument-new-js" */),
  "component---src-pages-app-instrument-provenance-[id]-js": () => import("./../../../src/pages/app/instrument/provenance/[id].js" /* webpackChunkName: "component---src-pages-app-instrument-provenance-[id]-js" */),
  "component---src-pages-app-instrument-redirectsubmit-js": () => import("./../../../src/pages/app/instrument/redirectsubmit.js" /* webpackChunkName: "component---src-pages-app-instrument-redirectsubmit-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-cpr-js": () => import("./../../../src/pages/cpr.js" /* webpackChunkName: "component---src-pages-cpr-js" */),
  "component---src-pages-filebrowser-js": () => import("./../../../src/pages/filebrowser.js" /* webpackChunkName: "component---src-pages-filebrowser-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-redirect-register-js": () => import("./../../../src/pages/redirect-register.js" /* webpackChunkName: "component---src-pages-redirect-register-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-terms-page-js": () => import("./../../../src/pages/termsPage.js" /* webpackChunkName: "component---src-pages-terms-page-js" */),
  "component---src-template-ins-detail-js": () => import("./../../../src/template/ins-detail.js" /* webpackChunkName: "component---src-template-ins-detail-js" */)
}

