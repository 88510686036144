/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "antd/dist/antd.css"
// import "antd/dist/antd.js"

import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import "firebase/functions"

import React from "react"
import AuthProvider from "./src/context/auth"

export const wrapRootElement = ({ element }) => (
  <AuthProvider>{element}</AuthProvider>
)
